import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import worldData from '../json/50m.json'; // 确保路径正确

const WorldMap = () => {
  const [hoveredCountry, setHoveredCountry] = useState("");
  const navigate = useNavigate();

  const handleMouseEnter = (geo) => {
    setHoveredCountry(geo.properties.name);
  };

  const handleMouseLeave = () => {
    setHoveredCountry("");
  };

  const handleClick = (geo) => {
    const countryName = geo.properties.name;
    navigate(`/login`);
  };

  return (
    <div style={{ position: "relative", backgroundColor: "black", padding: "20px" }}>
      <ComposableMap>
        <Geographies geography={worldData}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onMouseEnter={() => handleMouseEnter(geo)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(geo)}
                style={{
                  default: {
                    fill: hoveredCountry === geo.properties.name ? "#F53" : "#DDD",
                    outline: "none",
                  },
                  hover: {
                    fill: "#F53",
                    outline: "none",
                  },
                  pressed: {
                    fill: "#E42",
                    outline: "none",
                  },
                }}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
      {hoveredCountry && (
        <div style={{ position: "absolute", top: 10, left: 10, backgroundColor: "white", padding: "5px", borderRadius: "5px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
          {hoveredCountry}
        </div>
      )}
    </div>
  );
};

export default WorldMap;
