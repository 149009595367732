import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './component/Login';
import Member from './component/Member';
import WorldMap from './component/WorldMap';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WorldMap />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/index" element={<Member />} />
      </Routes>
    </Router>
  );
}

export default App;
