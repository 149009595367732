import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  Drawer, 
  List, 
  ListItem, 
  ListItemText 
} from '@mui/material';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Container } from '@mui/material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const API_PATH = process.env.REACT_APP_API_PATH;

const data = [
  { amount: '588USDT', fee: '30USDT' },
  { amount: '1088USDT', fee: '60USDT' },
  { amount: '2088USDT', fee: '150USDT' },
  { amount: '4088USDT', fee: '350USDT' },
  { amount: '8088USDT', fee: '750USDT' },
];

const AppBarWithHamburger = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [pwOpen, setpwOpen] = React.useState(false);
  const [pw, setpw] = React.useState('');
  const [npw, setnpw] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePwClickOpen = () => {
    setpwOpen(true);
  };

  const handlePwClose = () => {
    setpwOpen(false);
  };
  const navigate = useNavigate();

  const handleSubmit = async () => {
    // 构建请求体
    const requestBody = {
      oldPassword: pw,
      newPassword: npw,
    };
  
    try {
      const response = await axios.put(`${API_PATH}/customer/pw`, requestBody);
      if(response.status === 200) {
        alert('更新成功')
        handlePwClose();
      }   
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if(event.target.innerText === '资料列表') {
        navigate('/index', { replace: true });
      }

      if(event.target.innerText === '储值说明') {
        handleClickOpen();
      }

      if(event.target.innerText === '更改密码') {
        handlePwClickOpen();
      }

      if(event.target.innerText === '登出') {
        localStorage.clear();
        navigate('/login', { replace: true });
      }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = ['资料列表','储值说明','更改密码','登出'];

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor:'black' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2}}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Typography variant="h5" gutterBottom sx={{marginLeft:5,marginTop:2}}>
            {localStorage.getItem('name')}
        </Typography>
        <List sx={{ width: 250 }}>
          {menuItems.map((text) => (
            <ListItem button key={text} onClick={toggleDrawer(false)}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>

            <React.Fragment>
                <Dialog
                    open={pwOpen}
                    onClose={handlePwClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    更改密码
                    </DialogTitle>
                    <DialogContent>

                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="旧密码"
                    type="password"
                    onChange={(e)=>setpw(e.target.value)}
                    value={pw}
                    id="password"
                    autoComplete="current-password"
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="新密码"
                    type="password"
                    onChange={(e)=>setnpw(e.target.value)}
                    value={npw}
                    id="password"
                    autoComplete="current-password"
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleSubmit} autoFocus>
                    送出
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

      <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"交易信息"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        1. 请确保转账金额正确。
                        <br />
                        2. 请在 60 分钟内完成转账。转账时请再次确认地址和金额是否跟页面一致！
                        <br />
                        3. 交易成功后会自动充值到账。
                        <br />
                        4. 如因转账金额错误导致的充值失败，请及时联系客服将处理！
                    </DialogContentText>

                    {/* 添加表格 */}
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>充值数量</TableCell>
                            <TableCell>赠送数量</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.amount}</TableCell>
                                <TableCell>{row.fee}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <Container>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 5 }}>
                            {/* 显示二维码和钱包地址 */}
                            {localStorage.getItem('wallet') && (
                            <Box sx={{ textAlign: 'center' }}>
                                <Box sx={{ marginBottom: 2 }}>
                                <QRCode value={localStorage.getItem('wallet')} size={400} />
                                </Box>
                                <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                {localStorage.getItem('wallet')}
                                </Typography>
                            </Box>
                            )}
                        </Box>
                        </Container>

                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        同意
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
    </>
  );
};

export default AppBarWithHamburger;