import React from 'react';
import { LinearProgress, Box, Tooltip } from '@mui/material';

const BatteryProgress = ({ value }) => {
  // 根据进度条的值来设置颜色
  const getBatteryColor = (value) => {
    if (value >= 80) return '#76ff03'; // 高电量为绿色
    if (value >= 50) return '#ffee58'; // 中等电量为黄色
    return '#f44336'; // 低电量为红色
  };

  return (
    <Tooltip title={`${value}%`} arrow>
      <Box
        sx={{
          width: 50,
          height: 24,
          marginTop:1.5,
          border: '1px solid #000',
          borderRadius: '4px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            backgroundColor: '#e0e0e0',
            height: '100%',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getBatteryColor(value),
            },
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '4px',
            height: '100%',
            backgroundColor: '#000',
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default BatteryProgress;
