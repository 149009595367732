import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import dayjs from 'dayjs'; 
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import { DataGrid } from '@mui/x-data-grid';
import BatteryProgress from './BatteryProgress';
import Link from '@mui/material/Link';
import AppBarWithHamburger from './AppBarWithHamburger';
import BackgroundAnimate from './BackgroundAnimate';
import axios from 'axios';
import { Container, Box, CircularProgress,Grid,Tooltip } from '@mui/material';

const API_PATH = process.env.REACT_APP_API_PATH;

const LoadingGif = () => (
  <>
      <img src="/energy.gif" alt="Loading" style={{ width: '60%', height: 'auto',margin:'auto' }} />
  </>
);

const Member = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [service, setService] = useState('');
  const [option1, setOption1] = useState(0);
  const [option2, setOption2] = useState(0);
  const [option3,setOption3] = useState(0);
  const [option4, setOption4] = useState(0);
  const [option5,setOption5] = useState(0);
  const [option6,setOption6] = useState(0);
  const [name,setName]= useState('');
  const [memberPoint,setMemberPoint]= useState(0);
  const [account,setAccount]= useState('');

  const [option1Id, setOption1Id] = useState(0);
  const [option2Id, setOption2Id] = useState(0);
  const [option3Id,setOption3Id] = useState(0);
  const [option4Id, setOption4Id] = useState(0);
  const [option5Id,setOption5Id] = useState(0);
  const [option6Id,setOption6Id] = useState(0);

  const [option1point, setOption1point] = useState(0);
  const [option2point, setOption2point] = useState(0);
  const [option3point,setOption3point] = useState(0);
  const [option4point, setOption4Point] = useState(0);
  const [option5point,setOption5point] = useState(0);
  const [option6point,setOption6point] = useState(0);

  const [option1s, setOption1s] = useState([]);
  const [option2s, setOption2s] = useState([]);
  const [option3s,setOption3s] = useState([]);
  const [option4s,setOption4s] = useState([]);
  const [option5s,setOption5s] = useState([]);
  const [option6s,setOption6s] = useState([]);
  const [ageValues,setAgeValues] = useState([]);
  const [amount, setAmount] = useState(0);
  const [point, setPoint] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [minAmount, setMinAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');

  const [difference, setDifference] = useState(0);

  const navigate = useNavigate();

  const handleDataSubmit = async () => {

    if(option1Id === 0 || option2Id === 0 || option4Id === 0 || option3Id === 0 || option5Id === 0|| option6Id === 0 || amount === 0) {
      alert("请先选择完成所有选项 OR 数量不得为0");
      return;
    }
    if (!minAmount || !maxAmount) {
      alert("请确保输入框都已填写！");
      return;
    }

    if (minAmount >= maxAmount) {
      alert("年纪大小范围不正确");
      return;
    }
    const rangeString = `${minAmount}~${maxAmount}`;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("option1", option1);
    formData.append("option3", option3);
    formData.append("option5", option5);
    formData.append("option6", option6);
    formData.append("option2", option2);
    formData.append("amount", amount);
    formData.append("ageRange", rangeString);
    formData.append("option4", option4);
    formData.append("point", point);

    try {
      const response = await axios.post(`${API_PATH}/customer/data`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("上传成功:", response.data);
      alert("文件上传成功！");
      fetchData();
      setOpen(false);
    } catch (error) {
      console.error("上传失败:", error);
      alert("文件上传失败！");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleOption1Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption1Id(selectedId);
    }else {
      const selectedOption = option1s.find(o1 => o1.id === selectedId);
      setOption1(selectedOption.viewValue);
      setOption1Id(selectedId);
      setOption1point(selectedOption.realValue);
    }
  };

  const handleOption2Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption2Id(selectedId);
    }else {
      const selectedOption = option2s.find(o2 => o2.id === selectedId);
      setOption2(selectedOption.viewValue);
      setOption2point(selectedOption.realValue);
      setOption2Id(selectedId);
    }
  };

  const handleOption3Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption3Id(selectedId);
    }else {
      const selectedOption = option3s.find(o3 => o3.id === selectedId);
      setOption3(selectedOption.viewValue);
      setOption3point(selectedOption.realValue);
      setOption3Id(selectedId);
    }
  };

  const handleOption4CustomerMemoChange = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption4Id(selectedId);
    }else {
      const selectedOption = option4s.find(o4 => o4.id === selectedId);
      setOption4Point(selectedOption.realValue);
      setOption4(selectedOption.viewValue);
      setOption4Id(selectedId);
    }
  };

  const handleOption5Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption5Id(selectedId);
    }else {
      const selectedOption = option5s.find(o5 => o5.id === selectedId);
      setOption5(selectedOption.viewValue);
      setOption5point(selectedOption.realValue);
      setOption5Id(selectedId);
    }
  };

  const handleOption6Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption6Id(selectedId);
    }else {
      const selectedOption = option6s.find(o6 => o6.id === selectedId);
      setOption6(selectedOption.viewValue);
      setOption6point(selectedOption.realValue);
      setOption6Id(selectedId);
    }
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
    }
  };
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchUserAndOptionsData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/customer/information`);
      if (response.status === 200) { 
        setAccount(response.data.source.account);
        setName(response.data.source.name);
        setMemberPoint(response.data.source.point);
        setOption1s(response.data.source.option1s);     
        setOption2s(response.data.source.option2s);   
        setOption3s(response.data.source.option3s);   
        setOption4s(response.data.source.option4s);   
        setOption5s(response.data.source.option5s);   
        setOption6s(response.data.source.option6s);   
        setAgeValues(response.data.source.ageValues);   
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };

  const fetchServiceData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/customer/wallet`);
      if (response.status === 200) { 
        setService(response.data.source.customerService);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
};

  const handleMinAmountChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) { // 确保输入的是数字
      setMinAmount(value);
    }
  };

  // 处理最大值输入变化
  const handleMaxAmountChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) { // 确保输入的是数字
      setMaxAmount(value);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/customer/logs`);
      
      if (response.status === 200) { 
        setRows(response.data.source);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };

  const calculateDifference = (difference) => {
    // 将差距转换为数字
    const diff = parseFloat(difference) || 0;
  
    if (ageValues.length === 0) {
      return 0;
    }
  
    // 排序，以确保 boundary 的顺序是递增的
    const sortedValues = [...ageValues].sort((a, b) => a.boundary - b.boundary);
  
    // 找到大于或等于 diff 的第一个 boundary（如果存在）
    const boundaryIndex = sortedValues.findIndex(item => item.boundary > diff);
    
    if (boundaryIndex === -1) {
      // 如果 diff 大于所有的 boundary，返回最大的 boundary 对应的值
      return sortedValues[sortedValues.length - 1].value;
    } else if (boundaryIndex === 0) {
      // 如果 diff 小于最小的 boundary，返回最小 boundary 对应的值
      return sortedValues[0].value;
    } else {
      // 返回 diff 介于两个 boundary 之间的较小 boundary 对应的值
      return sortedValues[boundaryIndex - 1].value;
    }
  };

  const calculateDeadline = (createDate, deadlineMinutes) => {
    // 计算截止时间
    const deadline = dayjs(createDate).add(deadlineMinutes, 'minute');
    return deadline.format('MM-DD HH:mm'); // 格式化为你想要的格式
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份
    const day = String(date.getDate()).padStart(2, '0'); // 日期
    const hours = String(date.getHours()).padStart(2, '0'); // 小时
    const minutes = String(date.getMinutes()).padStart(2, '0'); // 分钟
  
    return `${month}-${day} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const min = parseFloat(minAmount) || 0;
    const max = parseFloat(maxAmount) || 0;
    if (max >= min) {
      setDifference(max - min);
    } else {
      setDifference(0);
    }
  }, [minAmount, maxAmount]);

  useEffect(() => {
    // 使用Axios从后端获取数据
    fetchData();
    fetchUserAndOptionsData();
    fetchServiceData();
  }, []);

  useEffect(() => {
    const agePoint = calculateDifference(difference);  
    const total = option1point+option2point+option3point+option4point+option5point+option6point+agePoint;
    console.log(`Option 1 Point: ${option1point}, Option 2 Point: ${option2point}, Option 3 Point: ${option3point}
      , Option 4 Point: ${option4point}, Option 5 Point: ${option5point}, Option 6 Point: ${option6point}, agePoint: ${agePoint}`);
    console.log(amount)
    console.log((total * amount).toFixed(5))
    setPoint((total * amount).toFixed(5));  
  }, [option1,option2,option3,option4,option5,option6,amount,difference]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'option1', headerName: '地区', width: 80 },
    { field: 'option2', headerName: '性别', width: 80 },
    { field: 'option3', headerName: '平台类型', width: 100 },
    { field: 'option4', headerName: '活跃度', width: 80 },
    { field: 'option5', headerName: '头像', width: 70 },
    { field: 'option6', headerName: '苹果安卓', width: 100 },
    { field: 'ageRange', headerName: '范围', width: 80 },
    {
      field: 'deadline',
      headerName: '预计完成',
      width: 100,
      renderCell: (params) => {
        const { createDate, timeLimit } = params.row; // 提交时间和加的分钟数
        if (timeLimit === null || timeLimit === 0) {
          return '提交中';
        }
        return `${calculateDeadline(createDate, timeLimit)}`;
      },
    },
    {
      field: 'pipeline',
      headerName: '进度条',
      width: 120,
      renderCell: (params) => {
        const value = params.value;
        if (value < 100) {
          return <LoadingGif/>;
        } else {
          return <BatteryProgress value={value} />;
        }
      },
    },
    { field: 'point', headerName: '点数', width: 100 },
    { field: 'amount', headerName: '数量', width: 80 },
    {
      field: 'uploadFileUrl',
      headerName: '上传文件',
      width: 100,
      renderCell: (params) => (
        params.value ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(params.value, '_blank')}
          >
            下载
          </Button>
        ) : (
          '尚未上传文件'
        )
      ),
    },
    {
      field: 'downloadFileUrl',
      headerName: '下载文件',
      width: 100,
      renderCell: (params) => (
        params.value ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(params.value, '_blank')}
          >
            下载
          </Button>
        ) : (
          '尚未下载文件'
        )
      ),
    },    
    {
      field: 'createDate',
      headerName: '提交时间',
      width: 100,
      renderCell: (params) => {
        return <span>{formatDate(params.value)}</span>;
      },
    },
    {
      field: 'status',
      headerName: '状态',
      width: 80,
      renderCell: (params) => {
        const status = params.value;
        const style = {
          color: status === '已完成' ? '#FFD700' : 'inherit', // 如果状态是“已完成”，则字体颜色为深黄色，否则为默认颜色
        };
        return <span style={style}>{status}</span>;
      },
    },
    { field: 'memo', headerName: '备注', width: 100 },
];




  return (
    <>
        <BackgroundAnimate />
        <AppBarWithHamburger/>    
        <Box sx={{ height: 800, width: '90%',margin:'auto', backgroundColor:'white',paddingTop:5,marginBottom:50,boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
        borderRadius: '8px', }} >
            <Typography variant="h3" gutterBottom style={{display:'flex',justifyContent:'center',marginTop:10}}>
                资料列表
            </Typography>
            <Typography variant="h4" gutterBottom style={{display:'flex',justifyContent:'center',marginTop:10,color:'red'}}>
            会员名称: {name}   帐号: {account}   点数: {memberPoint}
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
              <Typography variant="h6" gutterBottom style={{ marginTop: 5 }}>
                上传文件和下载文件保留两日，系统将会自动删除
              </Typography>
              <Typography variant="h4" gutterBottom style={{ marginTop: 5, color: 'red' }}>
                特殊要求请
                <Link href={'https://'+service}  underline="none" style={{ color: 'red' }}>
                  联系客服{service}
                </Link>
              </Typography>

            </Box>
            <Button variant="contained" endIcon={<SendIcon />} style={{marginBottom:20,marginLeft:20}} onClick={handleClickOpen}>
                上传
            </Button>
            {loading ? (
            <CircularProgress />
            ) : (
            <DataGrid rows={rows} columns={columns} pageSize={50} rowsPerPageOptions={[5]} style={{backgroundColor:'white'}}/>
            )}
        </Box>
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
            <DialogTitle>新增资料</DialogTitle>
                <DialogContent>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We
                    will send updates occasionally.
                </DialogContentText> */}
                
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">地区</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option1Id}
                            onChange={handleOption1Change}
                            label="1.地区"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option1s.map((o1) => (
                            <MenuItem key={o1.id} value={o1.id}>
                                {o1.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">性别</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option2Id}
                            onChange={handleOption2Change}
                            label="2.性别"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option2s.map((o2) => (
                            <MenuItem key={o2.id} value={o2.id}> 
                                {o2.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">平台类型</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option3Id}
                            onChange={handleOption3Change}
                            label="3.平台类型"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option3s.map((o3) => (
                            <MenuItem key={o3.id} value={o3.id}>
                                {o3.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">活跃度</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option4Id}
                            onChange={handleOption4CustomerMemoChange}
                            label="4.活跃度"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option4s.map((o4) => (
                            <MenuItem key={o4.id} value={o4.id}>
                                {o4.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">头像</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option5Id}
                            onChange={handleOption5Change}
                            label="5.头像"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option5s.map((o5) => (
                            <MenuItem key={o5.id} value={o5.id} >
                                {o5.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">苹果安卓</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option6Id}
                            onChange={handleOption6Change}
                            label="6.苹果安卓"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option6s.map((o6) => (
                            <MenuItem key={o6.id} value={o6.id} >
                                {o6.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid container alignItems="center" spacing={1} style={{ marginTop: 5 }}>
                      <Grid item xs={5}>
                        <TextField
                          required
                          margin="dense"
                          id="minAmount"
                          label="7.年纪最小值"
                          fullWidth
                          variant="standard"
                          value={minAmount}
                          onChange={handleMinAmountChange}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'center' }}>
                        <Typography variant="body1" component="span">~</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          required
                          margin="dense"
                          id="maxAmount"
                          label="年纪最大值"
                          fullWidth
                          variant="standard"
                          value={maxAmount}
                          onChange={handleMaxAmountChange}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="数量"
                        fullWidth
                        variant="standard"
                        value={amount}
                        onChange={handleAmountChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="点数"
                        fullWidth
                        variant="standard"
                        value={point}
                    />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                    <Typography variant="h6" component="div" gutterBottom>
                        文件上传
                    </Typography>
                    <Input
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' }} // 只接受特定文件类型
                    />
                    {selectedFile && (
                        <Typography variant="body1" component="div" sx={{ mt: 2 }}>
                        已选择文件: {selectedFile.name}
                        </Typography>
                    )}
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>取消</Button>
                <Button type="button" onClick={handleDataSubmit}>上传</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>

    </>
  );
};

export default Member;
